/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './components/dependencies';
import 'admin-lte/dist/css/adminlte.css';
function App() {
  const [route, setRoute] = useState([]);

  useEffect(() => {
    if (route.length === 0) {
      import('./routes').then((routes) => setRoute(routes.default));
    }
  }, [route.length]);
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          {route.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
